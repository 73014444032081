<template>
    <splitpanes vertical class="default-theme" align="center">
      <pane size="80">
        <Map :selectedDrone=this.selectedDrone @selectDrone="selectDrone($event)"/>
      </pane>
      <pane>
        <v-card v-if="this.dxrConfig!==undefined">
        <v-select v-model="selectedDrone" dense :items="dronelist" item-color="green"
                  label="Select drone" solo @change="selectDrone" ></v-select>
        <div v-if="this.selectedDrone!==''">
          <!--<v-card-text>{{selectedDrone}}</v-card-text> -->
          <v-card-actions>
            <video-player :streamId=drones[this.selectedDrone].mountpoint></video-player>
            </v-card-actions>
            <v-card-actions>
              <instrument-panel :droneId=this.selectedDrone></instrument-panel>
            </v-card-actions>
        </div>
        </v-card>
      </pane>
    </splitpanes>

</template>

<script>
import Map from "../component/TrackingMap"
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import VideoPlayer from "../component/VideoPlayer"
import InstrumentPanel from "../component/InstrumentPanel"
import { mapGetters } from "vuex"

export default {
  components: {
    Map,
    Splitpanes,
    Pane,
    VideoPlayer,
    InstrumentPanel

  },
  data: () => ({
    selectedDrone: '',
    dronelist: [],
    drones: {},
  }),
  computed: {
        ...mapGetters(["dxrConfig"]),
        dconfig() {
          return this.dxrConfig
        },
  },
  watch: {
    dconfig(newData, oldData) {
      // console.log('Telemetry updated', newData['DE-0001'].lat)
      this.updateDronelist(newData)
    }
  },
  mounted() {
    if (this.dxrConfig !== undefined) {
      this.updateDronelist(this.dxrConfig)
    }
  },
  methods: {
    updateDronelist(data) {
      this.dronelist = []
      for (let i=0; i<data.drones.length; i++) {
        this.dronelist.push(data.drones[i].drone_id)
        this.drones[data.drones[i].drone_id] = this.dxrConfig.drones[i]
      }
    },
    selectDrone(drone_id) {
      console.log("drone id selected: "+drone_id)
      this.selectedDrone = drone_id
    }
  }
}
</script>
