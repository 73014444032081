<template>
  <v-container style="height: 100%; width:100%;">
        <gmaps-map 
          :options="mapOptions"
          @mounted="setMapInstance">
            <gmaps-marker v-for="(value,drone_id) in telemetry" :key="drone_id"
              :position="{lat:value.lat,lng:value.lon}"
              :options="getOptions(drone_id, value)"
              :icon="geticon(value, drone_id)"
              :label="{ color: '#00000', fontWeight: 'bold', fontSize: '10px', text: drone_id}"
              @click="selectDrone(drone_id)"
            ></gmaps-marker>
          
        </gmaps-map>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { gmapsMap, gmapsMarker, gmapsPolygon, gmaps, gmapsCircle, gmapsPolyline } from "x5-gmaps" // import from 'x5-gmaps' for plugin
import { mapGetters } from "vuex"
import dpkg from '@/assets/dpkg.svg';

export default {
  name: "App",
  props: {
    selectedDrone: String
  },
  components: {
    gmapsMap,
    gmapsMarker,
    gmapsPolygon,
    gmapsCircle,
    gmapsPolyline
  },
  data() {
    return {
      deliveryPointsShown: [],
      regionCenter: null,
      rot: 0,
      map: null,
      mapOptions: {
        center: { lat: 40.9559329, lng: -74.8198401 },
        zoom: 15,
        scrollwheel: true,
        draggable: true
      },

    }
  },
  computed: {
    ...mapGetters(["regionId", "regionInfo", "telemetry"]),
    rinfo() {
      this.mapOptions.center.lat = this.regionInfo.geometry.lat;
      this.mapOptions.center.lng = this.regionInfo.geometry.lng;
      return this.regionInfo
    },
    rid() {
      return this.regionId
    },
    tupdate() {
      return this.telemetry
    }
  },
  watch: {
    tupdate(newData, oldData) {
      // console.log('Telemetry updated', newData['DE-0001'].lat)
    },
    rid(newRegion, oldRegion) {
      // console.log("region id updated: "+newRegion)
    },
    rinfo(newRegionInfo, oldRgionInfo) {
      this.regionCenter = {lng:newRegionInfo.geometry.lng, lat:newRegionInfo.geometry.lat}
      if (newRegionInfo.region_id !== oldRgionInfo.region_id) {
        this.map.setCenter(this.regionCenter)
      }
    }
  },
  mounted() {
    gmaps().then(maps => {
      this.maps = maps
    })
    /* setInterval(() => {
      this.rot += 5
    },25) */
  },
  methods: {
    selectDrone(drone_id) {
      console.log('drone selected: ', drone_id)
      this.$emit('selectDrone', drone_id)
    },

    getOptions(drone_id, value) {
        return {}
      /*  if (value.groundspeed>0.5) {
          return {animation:0, rotation:value.heading}
        }
        return {animation:0} */
    },
    geticon(data, drone_id) {
      let symbol = {
          labelOrigin: {x:0, y:-45},
          path: "m -15.71626,-15.95869 c 0.009,0.0284 1.30014,3.63188 2.86594,8.0049003 L -10,0 -12.86282,7.98786 c -1.57517,4.3929 -2.85656,7.99355 -2.85031,7.99923 0.009,0.0114 31.92536,-15.93598 31.972251,-15.97289 0.0125,-0.0114 -2.834691,-1.45105 -6.32882,-3.19742 -11.8513005,-5.9234597 -16.564311,-8.28035 -21.089811,-10.54069 -2.48465,-1.24375 -4.52862,-2.26602 -4.54424,-2.27454 -0.0156,-0.009 -0.0218,0.009 -0.0125,0.0398 z",
          fillColor: '#FF0000',
          fillOpacity: 0.5,
          anchor: {x:0,y:0},
          strokeWeight: 1,
          scale: 0.75,
          rotation: data.heading-90.0
      }
      if (this.selectedDrone === drone_id) {
        symbol.fillColor = '#00FF00'
        symbol.fillOpacity = 0.75
        symbol.scale = 0.85
      }

      return symbol
    },
    geticonpath(gripper) {
      if (gripper === 'closed') {
        return 'dpkg.svg'
      }
      return 'drone-nopkg.svg'
    },
    setMapInstance(map) {
      // console.log(map)
      this.map = map
    },
    
  }
}
</script>

<style>
#autocomplete {
  border-radius: 5px;
  border: 3px solid #73ad21;
  height: 40px;
  left: 40%;
  padding: 0 10px;
  top: 0;
  width: 100%;
  z-index: 1;
}


</style>
